import React, {
  FC, useContext, useEffect, useRef, useState,
} from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubmissionNavigation from './SubmissionNavigation';
import Inspection from './inspection/Inspection';
import Sign from './sign/Sign';
import { Spinner } from '../components';
import api from '../api';
import { prepareFormData, prepareFormDataForExistingSubmission } from './utils/prepare';
import { SubmissionFormSteps } from './types';
import {
  Submission, InspectionValue, InspectorType, SubmissionType,
} from '../types/submission';
import Template, { CategoryType, TemplateCategory, TemplateResponse } from '../types/template';
import useInspectionForm from './forms/useInspectionForm';
import useVehicleForm from './forms/useVehicleForm';
import useOverallForm, { OverallFormFields } from './forms/useOverallForm';
import { DriverLaunchDataContext } from '../context/LaunchDataContext';
import useAttachments from './forms/useAttachments';
import { getMismatches } from './mismatch-modal/helpers';
import { MismatchType } from './mismatch-modal/constants';
import { API_VERSION, NEXT_WORK_ORDER_NUMBER } from '../api/paths';
import S3SignedLinkFileUploader from './utils/S3SignedLinkFileUploader';
import { Log } from '../utils/Log';
import MismatchModal from './mismatch-modal/MismatchModal';
import LeaveConfirmationModal from './leave-confirmation-modal/LeaveConfirmationModal';
import { EventsManagerContext } from '../context/EventsManagerContext';
import { useAlert } from '../hooks';
import { PageMode } from '../pages';
import { getCurrentState, sortCategoriesByPriority } from './utils/utils';
import { DvirStatus } from '../types/types';
import SubmitDvirRequest, { DvirDefect, DvirDefectItem } from '../api/model/auxRequest/SubmitDvirRequest';
import { filterSeasonalCategoryItems } from '../driver/utils';
import { DvirFailedSubmissionRetrierContext } from '../context/DvirFailedSubmissionRetrier';
import { NextWorkOrderNumberResponse } from '../api/types';
import { StoreContext } from '../context/StoreContext';
import { DvirHistoryData } from '../home/types';
import { DvirSubmitMode } from '../constants';

interface SubmissionFormProps {
  currentSubmission: DvirHistoryData;
  goToHomePage: () => void;
  hasNotifiedFormForClosingPage: boolean;
  onApproveCloseDvir: () => void;
  onAbortCloseDvir: () => void;
  mode: PageMode;
}

const SubmissionForm: FC<SubmissionFormProps> = ({
  currentSubmission,
  goToHomePage,
  hasNotifiedFormForClosingPage,
  onAbortCloseDvir,
  onApproveCloseDvir,
  mode,
  currentDvirSubmitMode,
}) => {
  const { trailer, setPreTripCompleted, setPostTripCompleted } = useContext(StoreContext);

  const launchData = useContext(DriverLaunchDataContext);
  const {
    templateUrl, templateId, context, submissionUrl, allowTrailers: isIncludeTrailer,
  } = launchData;

  const { dvirScheduler } = useContext(DvirFailedSubmissionRetrierContext);

  const {
    location: { latitude, longitude },
    engineData,
    driverData,
    address,
    vin,
    inspectionTripType,
    vehicleNum,
    auxFunctions,
    setEngineData,
  } = useContext(EventsManagerContext);

  const [dvirData, setDvirData] = useState<Submission>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMismatchApproved, setIsMismatchApproved] = useState<boolean>(false);
  const [mismatchNotificationType, setMismatchNotificationType] = useState<MismatchType | null>(null);
  const [step, setStep] = useState<SubmissionFormSteps>(SubmissionFormSteps.Inspect);
  const [template, setTemplate] = useState<Template>(null);
  const [showLeaveConfirmationModal, setShowLeaveConfirmationModal] = useState<boolean>(false);

  const navigationCallbacksRef = useRef<{ onApprove: VoidFunction; onAbort: VoidFunction }>({
    onApprove: () => {},
    onAbort: () => {},
  });

  const hasTrailer = useRef(isIncludeTrailer === 'true' && !!trailer?.vin);

  const alert = useAlert();
  const { t } = useTranslation();

  const isVehicleDvirSubmit = currentDvirSubmitMode === DvirSubmitMode.Vehicle
    || currentDvirSubmitMode === DvirSubmitMode.VehicleTrailer;
  const isTrailerDvirSubmit = currentDvirSubmitMode === DvirSubmitMode.Trailer
    || currentDvirSubmitMode === DvirSubmitMode.VehicleTrailer;

  const {
    form: attachmentsForm,
    addAttachment,
    editNote,
    deleteNote,
    deletePhoto,
    hasFormChanged: hasAttachmentsFormChanged,
  } = useAttachments(template, dvirData);
  const {
    form: inspectionForm,
    validate: validateInspectionForm,
    handleFormChange: handleInspectionFormChange,
    hasFormChanged: hasInspectionFormChanged,
    incompleteInspectionCategoryName,
  } = useInspectionForm(template, dvirData, isVehicleDvirSubmit, isTrailerDvirSubmit);
  const {
    form: vehicleForm,
    validate: validateVehicleForm,
    handleFormChange: handleVehicleFormChange,
    hasFormChanged: hasVehicleFormChanged,
  } = useVehicleForm(engineData, dvirData ? dvirData.inspectionTripType : inspectionTripType);
  const {
    form: overallForm,
    isFormFilled: isOverallFormFilled,
    handleFormChange: handleOverallFormChange,
  } = useOverallForm({ hasTrailer: hasTrailer.current, isVehicleDvirSubmit, isTrailerDvirSubmit });

  const isReadOnlyMode = mode === PageMode.View || mode === PageMode.HistoryView;

  const convertTemplateStructure = (templateData: TemplateResponse): Template => {
    const trailerCategories: TemplateCategory[] = [];

    if (templateData?.trailerTemplate) {
      templateData.trailerTemplate.categories.forEach((category) => {
        trailerCategories.push({ ...category, categoryType: CategoryType.Trailer });
      });
    }

    const categories: TemplateCategory[] = [
      ...templateData.categories.map((category) => ({ ...category, categoryType: CategoryType.Vehicle })),
      ...trailerCategories,
    ];

    return {
      ...templateData,
      categories,
    };
  };

  const getSubmissionData = async () => {
    try {
      setIsLoading(true);
      if (isReadOnlyMode) {
        setTemplate(null);

        const currentVin = currentSubmission ? currentSubmission.vin : vin;
        const preSubmissionDate = `${context}|${currentVin}-`;
        const submissionDate = currentSubmission?.id.replace(preSubmissionDate, '');

        const submissionData: Submission = await api.get(
          `${submissionUrl}/${API_VERSION}/companies/${context}/vehicles/${currentVin}/submissions/${submissionDate}`,
          { vin: currentVin, submissionDate },
        );
        if (submissionData.engineHours) {
          setEngineData({ odometer: submissionData.odometer, engineHours: submissionData.engineHours });
        }
        setDvirData(sortCategoriesByPriority(submissionData));
      } else {
        auxFunctions.getAddress({});
        setDvirData(null);

        const trailerTemplateId = localStorage.getItem(`trailer_template_id_${driverData?.driverId}`);
        const templateResponse = await api.get<TemplateResponse>(`${templateUrl}/${API_VERSION}/${context}/${templateId}`, {
          id: templateId,
          trailerTemplateId: trailerTemplateId || '',
          isIncludeTrailer: hasTrailer.current ? 'true' : 'false',
        });

        const templateData = convertTemplateStructure(templateResponse);
        const seasonalTemplateData = filterSeasonalCategoryItems(templateData);

        setTemplate(seasonalTemplateData);
      }
    } catch (e) {
      alert.error({
        title: t('cloud_dvir__error_processing_dvir_record'),
        children: t('cloud_dvir__error_submit_dvir_descr'),
      });
    }
    setIsLoading(false);
  };

  if (dvirData && !overallForm[OverallFormFields.OverallVehicle].value) {
    handleOverallFormChange(OverallFormFields.OverallVehicle, getCurrentState(dvirData.events));
  }

  if (dvirData && !overallForm[OverallFormFields.OverallTrailer].value) {
    handleOverallFormChange(OverallFormFields.OverallTrailer, getCurrentState(dvirData.events));
  }

  const validateSubmissionForm = (): boolean => {
    const isValidVehicleForm = validateVehicleForm();
    const isValidInspectionForm = validateInspectionForm();

    return isValidVehicleForm && isValidInspectionForm;
  };

  const checkCriticalIssuesByType = (type: CategoryType): boolean => Object.entries(inspectionForm || {})
    .filter((a) => a[0].startsWith(`${type}-`))
    .map((a) => a[1])
    .flatMap((cat) => Object.values(cat.items))
    .some((i) => (i.isCritical && i.value === InspectionValue.NeedsRepair));

  const checkIssuesByType = (type: CategoryType): boolean => Object.entries(inspectionForm || {})
    .filter((a) => a[0].startsWith(`${type}-`))
    .map((a) => a[1])
    .flatMap((cat) => Object.values(cat.items))
    .some((i) => (i.value === InspectionValue.NeedsRepair));

  const checkIssues = () => {
    overallForm.hasCriticalIssue = {
      [OverallFormFields.OverallVehicle]: checkCriticalIssuesByType(CategoryType.Vehicle),
      [OverallFormFields.OverallTrailer]: checkCriticalIssuesByType(CategoryType.Trailer),
    };
    overallForm.hasIssue = {
      [OverallFormFields.OverallVehicle]: checkIssuesByType(CategoryType.Vehicle),
      [OverallFormFields.OverallTrailer]: checkIssuesByType(CategoryType.Trailer),
    };
  };

  const handleStepChange = (nextStep: SubmissionFormSteps): void => {
    if (nextStep === SubmissionFormSteps.Sign) {
      const isValid = isReadOnlyMode || validateSubmissionForm();

      if (!isValid) {
        return;
      }
    }

    overallForm[OverallFormFields.OverallVehicle].value = null;

    if (hasTrailer) {
      overallForm[OverallFormFields.OverallTrailer].value = null;
    }

    checkIssues();
    setStep(nextStep);
  };

  const handleApproveMismatch = () => {
    setIsMismatchApproved(true);
    setMismatchNotificationType(null);
  };

  const storeSignature = () => {
    const signId = driverData.driverId.replace(' ', '_');
    const signatureData = overallForm[OverallFormFields.Sign].value.src;
    if (!signatureData || !signatureData.startsWith('blob:')) {
      return;
    }
    fetch(signatureData)
      .then((response) => response.arrayBuffer())
      .then((data) => {
        const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(data)));
        const imageData = `data:image/jpeg;base64,${base64String}`;
        localStorage.setItem(`signature_${signId}`, imageData);
      });
  };

  const sendSubmission = async (submission) => {
    if (submission && !Object.keys(submission).length) {
      return;
    }

    setIsLoading(true);

    const submissionForm = { ...submission };

    try {
      if (mode !== PageMode.View && submissionForm.formData.status === DvirStatus.AwaitingMechanic) {
        try {
          const data = await api.get<NextWorkOrderNumberResponse>(`${templateUrl}/${API_VERSION}/${NEXT_WORK_ORDER_NUMBER}/${context}`, {});

          if (data.workOrderNumber) {
            submissionForm.formData = {
              ...submissionForm.formData,
              workOrderNumber: data.workOrderNumber,
            } as Submission;
          }
        } catch (e) {
          Log.e('Failed to receive work order number', e);
        }
      }

      let response: any;
      const signId = overallForm[OverallFormFields.Sign].value.id;
      const signatureData = overallForm[OverallFormFields.Sign].value.src;
      const signatureBase64 = await fetch(signatureData)
        .then((signatureResponse) => signatureResponse.arrayBuffer())
        .then((data) => btoa(String.fromCharCode.apply(null, new Uint8Array(data))));

      const dvirDefects: DvirDefect[] = submissionForm.formData.categories
        .map((category: { items: any[]; name: any }) => {
          const defectItems: DvirDefectItem[] = category.items
            .filter((item: { events: { safetyStatus: string }[] }) => item.events.some(
              (event: { safetyStatus: string }) => event.safetyStatus === InspectionValue.NeedsRepair,
            ))
            .map((item: { name: any; events: { safetyStatus: any }[]; critical: boolean }) => ({
              item: item.name,
              status: item.events[0].safetyStatus,
              critical: item.critical,
            }));
          return defectItems.length > 0 ? { category: category.name, items: defectItems } : null;
        })
        .filter(Boolean);
      let safeStatus;
      if (submissionForm.formData.status === DvirStatus.Completed && (submissionForm.formData.events && submissionForm.formData.events.length > 1)) {
        response = await api.put(`${submissionUrl}/${API_VERSION}/events`, {}, {
          companyId: submissionForm.formData.companyId,
          vin: submissionForm.formData.vin,
          submissionDate: submissionForm.formData.submissionDate,
          event: {
            inspectorId: driverData.driverId,
            inspectorType: InspectorType.Driver,
            safetyStatus: overallForm[OverallFormFields.OverallVehicle].value,
            signature: { id: signId, src: signatureBase64 },
            updatedTime: new Date().toISOString(),
          },
          initialSafetyStatus: submissionForm.formData.initialSafetyStatus,
        });
      } else {
        submissionForm.formData.events[0].signature = { id: signId, src: signatureBase64 };
        safeStatus = submissionForm.formData.initialSafetyStatus;
        response = await (api.put(`${submissionUrl}/${API_VERSION}`, {}, submissionForm.formData)
          .catch((e) => {
            dvirScheduler.saveSubmissionForm(submissionForm);
            const submitDvirRequest: SubmitDvirRequest = new SubmitDvirRequest(
              submissionForm.formData.inspectionTripType,
              safeStatus,
              submissionForm.formData.location,
              submissionForm.formData.odometer,
              submissionForm.formData.engineHours,
              JSON.stringify(dvirDefects),
              submissionForm.formData.submissionType,
              submissionForm.formData.vin,
              submissionForm.formData.driver,
            );
            auxFunctions.saveDvir(submitDvirRequest);

            Promise.reject(e);
          }));
      }

      submissionForm.attachments?.forEach((attachment) => {
        const signedUrl = response[attachment.id];
        if (signedUrl) {
          S3SignedLinkFileUploader.uploadImage(signedUrl, attachment.src)
            .then((data) => {
              Log.i('Successfully uploaded to S3:', data);
            })
            .catch((error) => {
              dvirScheduler.saveSubmissionAttachment(attachment);
              Log.e('Failed upload to S3:', error);
            });
        }
      });

      if (driverData.driverId) {
        storeSignature();
      }

      alert.success({ title: t('cloud_dvir__success_submit_dvir_title'), hideAfterSeconds: 5000 });
      if (submissionForm.formData.submissionType === SubmissionType.Vehicle) {
        if (submissionForm.formData.inspectionTripType === 'PRE_TRIP') {
          setPreTripCompleted(true);
        } else if (submissionForm.formData.inspectionTripType === 'POST_TRIP') {
          setPostTripCompleted(true);
        }

        const submitDvirRequest: SubmitDvirRequest = new SubmitDvirRequest(
          mode === PageMode.View ? '' : submissionForm.formData.inspectionTripType,
          safeStatus,
          submissionForm.formData.location,
          submissionForm.formData.odometer,
          submissionForm.formData.engineHours,
          JSON.stringify(dvirDefects),
          submissionForm.formData.submissionType,
          submissionForm.formData.vin,
          submissionForm.formData.driver,
        );
        auxFunctions.submitDvir(submitDvirRequest);
      }

      goToHomePage();
    } catch (e) {
      alert.error({
        title: t('cloud_dvir__error_submit_dvir_title'),
        children: t('cloud_dvir__error_submit_dvir_descr'),
      });

      Log.e('Failed to submit DVIR form', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!isMismatchApproved) {
      const {
        [CategoryType.Vehicle]: vehicleMismatchType = MismatchType.NoMismatch,
        [CategoryType.Trailer]: trailerMismatchType = MismatchType.NoMismatch,
      } = getMismatches(inspectionForm, overallForm, isVehicleDvirSubmit, isTrailerDvirSubmit);

      if (mode === PageMode.View) {
        setMismatchNotificationType(null);
      } else if (vehicleMismatchType !== MismatchType.NoMismatch) {
        setMismatchNotificationType(vehicleMismatchType);
        return;
      } else if (trailerMismatchType !== MismatchType.NoMismatch) {
        setMismatchNotificationType(trailerMismatchType);
        return;
      }
    }

    if (!submissionUrl) {
      return;
    }

    if (mode === PageMode.View) {
      const submission = prepareFormDataForExistingSubmission(dvirData, overallForm, driverData, DvirStatus.Completed);

      sendSubmission(submission);

      return;
    }

    const { [CategoryType.Vehicle]: vehicleSubmission, [CategoryType.Trailer]: trailerSubmission } = prepareFormData({
      vehicleForm,
      inspectionForm,
      overallForm,
      attachmentsForm,
      launchData,
      latitude,
      longitude,
      address,
      timeToLive: template.timeToLive,
      driverData,
      vin,
      vehicleNum,
      templateName: template.name,
      hasTrailer: hasTrailer.current,
      trailerData: trailer,
      isVehicleDvirSubmit,
      isTrailerDvirSubmit,
    });

    sendSubmission(vehicleSubmission);

    if (hasTrailer.current && trailerSubmission) {
      sendSubmission(trailerSubmission);
    }
  };

  const hasFormChanged = (): boolean => hasInspectionFormChanged() || hasAttachmentsFormChanged() || hasVehicleFormChanged();

  const handleApproveCloseDvir = () => {
    setShowLeaveConfirmationModal(false);
    onApproveCloseDvir();
  };

  const handleAbortCloseDvir = () => {
    setShowLeaveConfirmationModal(false);
    onAbortCloseDvir();
  };

  const handleApproveExit = () => {
    goToHomePage();
  };

  const handleAbortExit = () => {
    setShowLeaveConfirmationModal(false);
  };

  const handleExit = () => {
    if (!isReadOnlyMode && hasFormChanged()) {
      navigationCallbacksRef.current.onApprove = handleApproveExit;
      navigationCallbacksRef.current.onAbort = handleAbortExit;

      setShowLeaveConfirmationModal(true);

      return;
    }

    goToHomePage();
  };

  useEffect(() => {
    if (templateUrl && context) {
      getSubmissionData();
    }
  }, [mode, templateUrl, templateId, context]);

  useEffect(() => {
    if (hasNotifiedFormForClosingPage) {
      if (hasFormChanged()) {
        navigationCallbacksRef.current.onApprove = handleApproveCloseDvir;
        navigationCallbacksRef.current.onAbort = handleAbortCloseDvir;

        setShowLeaveConfirmationModal(true);

        return;
      }

      onApproveCloseDvir();
    }
  }, [hasNotifiedFormForClosingPage]);

  const content = {
    [SubmissionFormSteps.Inspect]: (
      <Inspection
        incompleteInspectionCategoryName={incompleteInspectionCategoryName}
        readOnly={isReadOnlyMode}
        isVehicleDvirSubmit={isVehicleDvirSubmit}
        isTrailerDvirSubmit={isTrailerDvirSubmit}
        vehicleForm={vehicleForm}
        onVehicleFormChange={handleVehicleFormChange}
        inspectionForm={inspectionForm}
        onInspectionFormChange={handleInspectionFormChange}
        attachmentsForm={attachmentsForm}
        onAddAttachment={addAttachment}
        onEditNote={editNote}
        onDeleteNote={deleteNote}
        onDeletePhoto={deletePhoto}
        isDriverSignOff={mode === PageMode.View}
      />
    ),
    [SubmissionFormSteps.Sign]: (
      <Sign
        overallForm={overallForm}
        isSignReadOnly={mode === PageMode.HistoryView}
        onFormChange={handleOverallFormChange}
        isOverallInspectionReadOnly={isReadOnlyMode}
        hasTrailer={hasTrailer.current}
        submissionType={dvirData?.submissionType}
        isVehicleDvirSubmit={isVehicleDvirSubmit}
        isTrailerDvirSubmit={isTrailerDvirSubmit}
      />
    ),
  };

  return (
    <>
      {isLoading && <Spinner />}
      <Box>
        <SubmissionNavigation
          isSubmitDisabled={!isOverallFormFilled || mode === PageMode.HistoryView}
          step={step}
          onStepChange={handleStepChange}
          onExit={handleExit}
          onSubmit={handleSubmit}
        />
        <Box
          sx={{
            paddingTop: 5,
            maxWidth: (theme) => theme.grid.tablet,
            margin: '0 auto',
            width: '100%',
          }}
        >
          {inspectionForm && content[step]}
        </Box>

        {!!mismatchNotificationType && (
          <MismatchModal onApprove={handleApproveMismatch} mismatchNotificationType={mismatchNotificationType} />
        )}

        {showLeaveConfirmationModal && (
          <LeaveConfirmationModal
            onApprove={navigationCallbacksRef.current.onApprove}
            onAbort={navigationCallbacksRef.current.onAbort}
          />
        )}
      </Box>
    </>
  );
};

export default SubmissionForm;
